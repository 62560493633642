import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    specialProjects: [],
    specialProjectById: null,
};

const SpecialProjectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_SPECIALPROJECT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_SPECIALPROJECT_SUCCESS:
            return { ...state, loading: false, specialProjects: payload };
        case actionTypes.GET_SPECIALPROJECT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_SPECIALPROJECT_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_SPECIALPROJECT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                specialProjectById: payload,
            };
        case actionTypes.GET_SPECIALPROJECT_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_SPECIALPROJECT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_SPECIALPROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                specialProjects: [payload, ...state.specialProjects], // No changes
            };
        case actionTypes.ADD_SPECIALPROJECT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_SPECIALPROJECT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_SPECIALPROJECT_SUCCESS:
            return { ...state, loading: false, specialProjectById: payload };
        case actionTypes.UPDATE_SPECIALPROJECT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_SPECIALPROJECT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_SPECIALPROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                specialProjects: state.specialProjects.filter(
                    (specialProject) => specialProject.SpecialProject_id !== payload
                ),
            };
        case actionTypes.DELETE_SPECIALPROJECT_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default SpecialProjectReducer;