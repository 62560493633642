import React, { useState, useEffect } from "react";
import {
  Grid,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { AddIncubatees, GetIncubateesById, UpdateIncubatees } from "../../../Redux/action";

const IncubateesForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const type = location?.state?.type;
  const idFromLocation = location?.state?.Incubation_Id;
  const { incubateeById } = useSelector((store) => store.incubateesReducer);


  console.log('idFromLocation', idFromLocation)
  console.log('type', type)
  // Default payload structure
  const payloadObj = {
    Name: "",
  };

  const [payload, setPayload] = useState(payloadObj);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleResponseAddIncubatees = (success = false, res) => {
    if (success) {
      enqueueSnackbar(`Incubatees added successfully.`, { variant: "success" });
    navigate("/incubatees");
    } else {
      enqueueSnackbar(
        `${res?.data?.message || "Something went wrong, please check all fields."}`,
        { variant: "error" }
      );
    }
  };

  const handleResponseUpdateIncubatees = (success = false, res) => {
    if (success) {
      enqueueSnackbar(`Incubatees updated successfully.`, { variant: "success" });
    navigate("/incubatees");
    } else {
      enqueueSnackbar(
        `${res?.data?.error || "Something went wrong, please check all fields."}`,
        { variant: "error" }
      );
    }
  };


  const handleClick = () => {
    console.log("Payload Content:", payload);
  
    if (type === "add") {
      dispatch(AddIncubatees(payload, handleResponseAddIncubatees));
    } else {
      dispatch(
        UpdateIncubatees(idFromLocation, payload, handleResponseUpdateIncubatees)
      );
    }
  };
  


  useEffect(() => {
    if (idFromLocation) {
    
      dispatch(GetIncubateesById(idFromLocation));
    }
  }, [idFromLocation, dispatch]);

  useEffect(() => {
    if (idFromLocation && Array.isArray(incubateeById)) {
      
      const foundIncubatee = incubateeById.find(
        (item) => item.Incubation_Id === idFromLocation
      );

      if (foundIncubatee) {
        setPayload({
          ...foundIncubatee,
          Name: foundIncubatee.Name || "",
        });
      }
    }
  }, [incubateeById, idFromLocation]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
          Incubatee Management
        </MDTypography>
      </MDBox>
      <MDBox
        mx={2}
        mt={3}
        py={3}
        px={4}
        mb={2}
        borderRadius="lg"
        variant="gradient"
        coloredShadow="info"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pb={2}
        >
          <MDInput
            required
            type="text"
            label="Name of Incubatee"
            name="Name"
            value={payload?.Name || ""}
            margin="dense"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton variant="gradient" color="info" onClick={handleClick}>
          {type === "add" ? "Add Incubatee" : "Update Incubatee"}
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
};

export default IncubateesForm;

