import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Card, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { DeleteIncubatees, GetIncubatees } from "../../Redux/action"; 

export default function Incubatees() {
    // const truncateText = (text, maxLength) => {
    //     return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    // };

    const Author = ({ image, Title }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            {/* <MDAvatar src={image} name={Title} size="sm" /> */}
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {Title}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const { incubatees, loading } = useSelector((store) => store.incubateesReducer);
    const incubateesData = Array.isArray(incubatees) ? incubatees : [];
    console.log("incubateesData", incubateesData)
    console.log("deleteId", deleteId)

    const handleEditClick = (Incubation_Id) => {
        console.log("Incubation_Id", Incubation_Id)
        navigate("/incubatees/edit/", { state: { type: "edit", Incubation_Id: Incubation_Id } });
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = async () => {
        try {
            console.log('deleteId', deleteId)
            await dispatch(DeleteIncubatees(deleteId));
            dispatch(GetIncubatees());
            handleClose();
            enqueueSnackbar("Incubatees deleted successfully.", {
                variant: "success",
            });
        } catch (error) {
            console.error("Error deleting Incubatees:", error);
        }
    };

    const columns = [
        {
            Header: "incubatees name",
            accessor: "incubatees name",
            align: "left",
            width: "75%",
        },
       
        { Header: "action", accessor: "action", align: "center" },
    ];

    // ?.filter((announcement) => !announcement.Is_deleted)
    const rows = Array.isArray(incubateesData)
        ? incubateesData.map((data) => ({
            "incubatees name": (
                <Author Title={`${data?.Name}`} />
            ),
           
            action: (
                <>
                    <MDBadge
                        badgeContent={"Edit"}
                        sx={{ cursor: "pointer" }}
                        fontWeight="medium"
                        onClick={() => handleEditClick(data?.Incubation_Id)}
                    />
                    <MDBadge
                        color={"dark"}
                        badgeContent={"Delete"}
                        sx={{ cursor: "pointer" }}
                        fontWeight="medium"
                        onClick={() => handleClickOpen(data?.Incubation_Id)}
                    />
                </>
            ),
        }))
        : [];


    const handleGetIncubatees = async () => {
        try {
            await dispatch(GetIncubatees());
        } catch (error) {
            console.error("Error fetching incubatees:", error);
        }
    };

    useEffect(() => {
        handleGetIncubatees();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Incubatees
                                </MDTypography>
                                <MDBox>
                                    <Tooltip title="Add a new incubatee">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/incubatees/add", { state: { type: "add" } });
                                            }}
                                            variant="outlined"
                                            color="white"
                                        // startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Incubatees
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {incubateesData && !loading ? (
                                    incubateesData.length > 0 ? (
                                        <DataTable
                                            table={{ columns, rows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    ) : (
                                        <Grid
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                margin: "15.7%",
                                            }}
                                        >
                                            <img
                                                src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                width={"80px"}
                                                height={"80px"}
                                                alt="No data"
                                            />
                                            <MDTypography fontSize="12px">
                                                No incubatees found. Please add an incubatee to continue.
                                            </MDTypography>
                                        </Grid>
                                    )
                                ) : (
                                    <span style={{ fontSize: "15px", margin: "2rem" }}>Loading...</span>
                                )}
                            </MDBox>

                            <Dialog open={open} onClose={handleClose}>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this incubatees?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>Disagree</MDButton>
                                    <MDButton onClick={handleDeleteClick} autoFocus>
                                        Agree
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
