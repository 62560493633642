import React, { useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Tooltip,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSpecialProject, GetSpecialProjectById, GetSpecialProjects } from "../../Redux/action";
import { useState } from "react";
import { useSnackbar } from "notistack";

export default function SpecialProjects() {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const Author = ({ image, Title }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={Title} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {Title}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ Url, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography
                display="block"
                variant="caption"
                color="text"
                fontWeight="medium"
            >
                {Url}
            </MDTypography>
            <MDTypography variant="caption">
                {truncateText(description, 100)}
            </MDTypography>
        </MDBox>
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const { specialProjects, loading } = useSelector((store) => store.SpecialProjectReducer);
    const specialProjectsData = specialProjects || [];
    console.log("specialProjectsData", specialProjects);

    const handleEditClick = (id) => {
        navigate("/special-project/edit/", { state: { type: "edit", id: id } });
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = async () => {
        try {
            await dispatch(DeleteSpecialProject(deleteId));
            dispatch(GetSpecialProjects());
            handleClose();
            enqueueSnackbar("Special Project is deleted successfully.", {
                variant: "success",
            });
        } catch (error) {
            console.error("Error deleting special project:", error);
        }
    };

    const columns = [
        {
            Header: "title",
            accessor: "special project title",
            align: "left",
            width: "25%",
        },
        {
            Header: "description",
            accessor: "url & description",
            align: "left",
            width: "50%",
        },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = Array.isArray(specialProjectsData)
        ? specialProjectsData
            .filter((project) => project.Is_deleted === 0)
            .map((data) => ({
                "special project title": (
                    <Author image={`${docUrl}${data?.Pro_image}`} Title={`${data?.Title}`} />
                ),
                "url & description": (
                    <Job Url={data?.Url} description={data?.Description} />
                ),
                action: (
                    <>
                        <MDBadge
                            badgeContent={"Edit"}
                            sx={{ cursor: "pointer" }}
                            fontWeight="medium"
                            onClick={() => handleEditClick(data?.Project_id)}
                        />
                        <MDBadge
                            color={"dark"}
                            badgeContent={"Delete"}
                            sx={{ cursor: "pointer" }}
                            fontWeight="medium"
                            onClick={() => {
                                if (data?.Project_id) handleClickOpen(data?.Project_id);
                            }}
                        />
                    </>
                ),
            }))
        : [];


    const handleGetSpecialProjects = async () => {
        try {
            await dispatch(GetSpecialProjects());
        } catch (error) {
            console.error("Error fetching special projects:", error);
        }
    };

    useEffect(() => {
        handleGetSpecialProjects();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Special Projects
                                </MDTypography>
                                <MDBox className="ticket_btnsHeaderCont">
                                    <Tooltip title="Add a new special project">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/special-project/add", { state: { type: "add" } });
                                            }}
                                            variant="outlined"
                                            color="white"
                                            startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Add Special Project
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {specialProjectsData && !loading ? (
                                    specialProjectsData?.length > 0 ? (
                                        <DataTable
                                            table={{ columns, rows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    ) : (
                                        <Grid
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                margin: "15.7%",
                                            }}
                                        >
                                            <img
                                                src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                width={"80px"}
                                                height={"80px"}
                                                alt=""
                                            />
                                            <MDTypography fontSize="12px">
                                                No special project found. Please add a project to continue.
                                            </MDTypography>
                                        </Grid>
                                    )
                                ) : (
                                    <span style={{ fontSize: "15px", margin: "2rem" }}>
                                        Loading...
                                    </span>
                                )}
                            </MDBox>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this special project?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>Disagree</MDButton>
                                    <MDButton onClick={handleDeleteClick} autoFocus>
                                        Agree
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
