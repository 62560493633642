import * as React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    ImageList,
    ImageListItem,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AddEvent, GetEventById, UpdateEvent } from "../../../Redux/action";
import DeleteIcon from "@mui/icons-material/Delete";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const EventForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { eventById } = useSelector((store) => store.EventReducer);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [imagePreview, setImagePreview] = useState("");
    const [fileInputs, setFileInputs] = useState({
        files: null,
    });

    const payloadObj = {
        Title: "",
        Description: "",
        Start_date: "",
        End_date: "",
        Link: "",
        Status: "",
        User_role: "admin",
        files: [],
    };

    const [payload, setPayload] = useState(
        idFromLocation
            ? {
                ...eventById,
                Images: eventById?.Images || [],
                files: eventById?.files || [],
            }
            : payloadObj
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filesValue = e.target.files?.[0];
        if (name === "files") {
            const temp = URL.createObjectURL(filesValue);
            if (temp) {
                setImagePreview(temp);
            }
            setFileInputs({ ...fileInputs, [name]: filesValue });
        } else {
            setPayload({ ...payload, [name]: value });
        }
    };

    const handleRemoveImages = (i) => {
        const updatedImages = payload?.Images?.filter((_, index) => index !== i);
        setPayload({ ...payload, Images: updatedImages || [] });
    };

    const handleResponseAddEvent = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Event added successfully.`, { variant: "success" });
            navigate("/events");
        } else {
            enqueueSnackbar(
                `${res?.data?.message || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleResponseUpdateEvent = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Event updated successfully.`, { variant: "success" });
            navigate("/events");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (key === "Images") {
                var stringifiedImg = JSON.stringify(value);
                formData.append(key, stringifiedImg);
            } else {
                formData.append(key, value);
            }
        });

        Object.entries(fileInputs).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (type === "add") {
            dispatch(AddEvent(formData, handleResponseAddEvent));
        } else {
            dispatch(
                UpdateEvent(idFromLocation, formData, handleResponseUpdateEvent)
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetEventById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation && eventById) {
            const updatedPayload = {
                ...eventById,
                Images:
                    typeof eventById?.Images === "string"
                        ? JSON.parse(eventById?.Images)
                        : eventById?.Images,
            };
            setPayload(updatedPayload);
        }
    }, [eventById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("payload", payload);
    console.log("fileInputs", fileInputs);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Event Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        required
                        type="text"
                        label="Title"
                        name="Title"
                        value={payload?.Title || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    {/* <MDInput
                        required
                        type="text"
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    /> */}
                    <TextField
                        required
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4} // Adjust the number of rows as needed
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    {/* <MDInput
                        required
                        type="date"
                        label="Start date"
                        name="Start_date"
                        value={payload?.Start_date?.slice(0, 10) || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <MDInput
                        required
                        type="date"
                        label="End date"
                        name="End_date"
                        value={payload?.End_date?.slice(0, 10) || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    /> */}
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            gap={3}
                            sx={{
                                "@media (max-width: 600px)": {
                                    paddingTop: "1.5rem",
                                },
                                ...widthStyle,
                            }}
                        >
                            <MDTypography
                                variant="h6"
                                sx={{ fontSize: "14px", fontWeight: "400" }}
                            >
                                Start date
                            </MDTypography>
                            <MDInput
                                required
                                type="date"
                                // label=""
                                name="Start_date"
                                value={payload?.Start_date?.slice(0, 10) || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            gap={3}
                            sx={{
                                "@media (max-width: 600px)": {
                                    paddingTop: "2rem",
                                },
                                ...widthStyle,
                            }}
                        >
                            <MDTypography
                                variant="h6"
                                sx={{ fontSize: "14px", fontWeight: "400" }}
                            >
                                End date
                            </MDTypography>
                            <MDInput
                                required
                                type="date"
                                // label=""
                                name="End_date"
                                value={payload?.End_date?.slice(0, 10) || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <MDInput
                        required
                        type="text"
                        label="Link"
                        name="Link"
                        value={payload?.Link || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <FormControl sx={{ width: "45%" }} component="fieldset" required>
                        <FormLabel>Status</FormLabel>
                        <RadioGroup
                            row
                            aria-label="status"
                            name="Status"
                            value={payload?.Status || ""}
                            onChange={handleChange}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControlLabel
                                value="active"
                                control={<Radio />}
                                label="Active"
                            />
                            <FormControlLabel
                                value="inactive"
                                control={<Radio />}
                                label="Inactive"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        gap={3}
                        // pt={2}
                        sx={widthStyle}
                    >
                        <MDTypography
                            variant="h6"
                            sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                            Upload Event Image (Preferred dimensions are 1800 x 1200)
                        </MDTypography>
                        <MDInput
                            type="file"
                            name="files"
                            margin="dense"
                            onChange={handleChange}
                        />
                        {imagePreview ? (
                            <img
                                src={`${imagePreview}`}
                                alt="Event images"
                                style={{
                                    width: "50%",
                                    aspectRatio: "3/2",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <MDTypography
                                variant="h6"
                                sx={{ fontSize: "14px", fontWeight: "400" }}
                            >
                                No latest images selected yet.
                            </MDTypography>
                        )}

                        {type === "edit" && payload?.Images ? (
                            <ImageList
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginTop: "2rem",
                                    padding: "5px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    "@media (max-width: 961px)": {
                                        justifyContent: "center",
                                    },
                                }}
                                rowHeight={164}
                            >
                                {type === "edit" &&
                                    payload?.Images &&
                                    payload?.Images.map((file, index) => (
                                        <ImageListItem key={index} style={{ width: "200px" }}>
                                            <img
                                                src={`${docUrl}${file}`}
                                                alt={"Event Images"}
                                                style={{
                                                    width: "100%",
                                                    aspectRatio: "3/2",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            <MDButton
                                                component="button"
                                                variant="body2"
                                                onClick={() => handleRemoveImages(index)}
                                                fullWidth
                                                style={{ marginTop: "6px" }}
                                            >
                                                Remove the photo <DeleteIcon />
                                            </MDButton>
                                        </ImageListItem>
                                    ))}
                            </ImageList>
                        ) : null}
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add" ? "Add Event" : "Update Event"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default EventForm;
