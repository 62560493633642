import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    incubatees: [],
    incubateeById: null,
};

const IncubateeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_INCUBATEE_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_INCUBATEE_SUCCESS:
            return { ...state, loading: false, incubatees: payload };
        case actionTypes.GET_INCUBATEE_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_INCUBATEE_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_INCUBATEE_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                incubateeById: payload,
            };
        case actionTypes.GET_INCUBATEE_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_INCUBATEE_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_INCUBATEE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                incubatees: [payload, ...state.incubatees],
            };

        case actionTypes.ADD_INCUBATEE_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_INCUBATEE_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_INCUBATEE_SUCCESS:
            return { ...state, loading: false, incubateeById: payload };
        case actionTypes.UPDATE_INCUBATEE_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_INCUBATEE_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_INCUBATEE_SUCCESS:
            return {
                ...state,
                loading: false,
                incubatees: state.incubatees.filter(
                    (incubatee) => incubatee.Incubatee_id !== payload
                ),
            };
        case actionTypes.DELETE_INCUBATEE_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default IncubateeReducer;
